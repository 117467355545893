import React from 'react';
import { string } from 'prop-types';
import { Box, AspectRatio, Text, Button, Center, Link, Heading } from '@chakra-ui/react';

import {
  THE_BRIDE,
  YOUTUBE_EMBED,
  YOUTUBE_LINK,
  WEDDING_AKAD_TIME,
  WEDDING_AKAD_FULLDATE,
} from '@/constants';
import { BUTTON_PADDING_PROPS, BUTTON_PROPS } from '@/constants/colors';

import WithAnimation from '@/components/Common/WithAnimation';
import { ENABLE_LIVE_STREAMING, ENABLE_VIDEO_PREWEDDING } from '@/constants/feature-flags';
import txt from './locales';

/**
 * @function YoutubeLiveSection
 * Render Live Streaming component
 * @returns {JSX.Element}
 */
function YoutubeLiveSection({ lang, ...rest }) {
  return (
    <Box position="relative" textAlign="center" {...rest}>
      {/* LIVE STREAMING SECTION */}
      <Box width="100%">
        <WithAnimation>
          <Heading
            fontWeight="normal"
            size="xl"
            textAlign="center"
            margin="0"
            color="secondaryColorText"
            fontFamily="CustomFont"
            marginBottom="24px"
          >
            {ENABLE_VIDEO_PREWEDDING ? 'prewed video' : 'live streaming'}
          </Heading>
        </WithAnimation>
        {ENABLE_LIVE_STREAMING && (
          <WithAnimation>
            <Text
              fontSize="md"
              textAlign="center"
              color="mainColorText"
              marginBottom="16px"
              dangerouslySetInnerHTML={{
                __html: `${WEDDING_AKAD_FULLDATE} | ${WEDDING_AKAD_TIME}`,
              }}
            />
          </WithAnimation>
        )}
        {/* Live stream section */}
        <WithAnimation right>
          <Box pos="relative">
            <AspectRatio maxW="560px" ratio={16 / 9} boxShadow="xl">
              <iframe
                id="ytplayer"
                title={`Live wedding of ${THE_BRIDE}`}
                src={YOUTUBE_EMBED}
                allowFullScreen
                style={{ borderRadius: '8px' }}
                loading="lazy"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              />
            </AspectRatio>
            
          </Box>
        </WithAnimation>
        <WithAnimation right>
          <Text
            textAlign="center"
            fontSize="md"
            color="mainColorText"
            margin="16px 0"
            padding="0 12px 0 12px"
          >
            {txt.problem[lang]}
          </Text>
        </WithAnimation>
        <WithAnimation left>
          <Center>
            <Link href={YOUTUBE_LINK} target="_blank" _hover={{ textDecoration: 'none' }}>
              <Button
                size="xs"
                fontWeight="normal"
                bgColor="bgAlternative"
                marginBottom="16px"
                {...BUTTON_PROPS}
                {...BUTTON_PADDING_PROPS}
                fontSize="md"
              >
                {txt.open[lang]}
              </Button>
            </Link>
          </Center>
        </WithAnimation>
      </Box>
    </Box>
  );
}

YoutubeLiveSection.propTypes = {
  lang: string.isRequired,
};

export default React.memo(YoutubeLiveSection);
